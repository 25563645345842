import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ButtonB from "../components/ButtonB"

import "../styles/engineering-challenge.scss"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="thanks-bkg">
      <div className="eng-chal-content">
        <div className="transparent">
          <div className="outer-container">
            <div className="inner-container">
              <div className="column">
                <h1>404: Not Found</h1>
                <h2
                  style={{
                    width: "100%",
                    textTransform: "none",
                    fontWeight: "500",
                  }}
                >
                  You just hit a route that doesn't exist.
                </h2>
                <ButtonB href="/">Go Home</ButtonB>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
